<template>
  <v-container>
    <v-row class="d-flex justify-center align-center">
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        :lg="isContact ? 12 : 6"
        :xl="isContact ? 12 : 6"
        v-for="location in locations"
        :key="location.id"
      >
        <LocationItem :location="location" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LocationItem from "@/components/LocationItem";
export default {
  components: {
    LocationItem,
  },
  data() {
    return {
      locations: [
        {
          id: 1,
          city: "Sterkliniek Dierenartsen Heythuysen",
          address: "Kloosterstraat 42, 6093 CX Heythuysen",
          img: "https://www.sterkliniekamsterdam.nl/images/SterkliniekAmsterdam/Krugerplein/Krugerplein%20Gevel.JPG",
          openinghours: {
            monday: {
              morning: "00:01 - 12:00",
              afternoon: "12:01 - 00:00",
            },
            tuesday: {
              morning: "00:01 - 12:00",
              afternoon: "12:01 - 00:00",
            },
            wednesday: {
              morning: "00:01 - 12:00",
              afternoon: "12:01 - 00:00",
            },
            thursday: {
              morning: "00:01 - 12:00",
              afternoon: "12:01 - 00:00",
            },
            friday: {
              morning: "00:01 - 12:00",
              afternoon: "12:01 - 00:00",
            },
            saturday: {
              morning: "00:01 - 12:00",
              afternoon: "12:01 - 00:00",
            },
            sunday: {
              morning: "00:01 - 12:00",
              afternoon: "12:01 - 00:00",
            },
          },
        },
      ],
    };
  },
  props: {
    isContact: Boolean,
  },
};
</script>

<style>
</style>