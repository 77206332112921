<template>
  <div class="banner">
    <div class="name accent">
      {{ name }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "Sterkliniek Dierenartsen Heythuysen",
    };
  },
};
</script>

<style scoped lang="scss">
.banner {
  width: 100%;
  height: 60vh;
  background-image: url("https://jimdo-storage.freetls.fastly.net/image/2627de04-1275-487a-b918-0c2d1255d985.jpg?format=pjpg&quality=80&auto=webp&disable=upscale&width=1600&height=1067");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .name {
    z-index: 2;
    color: white;
    font-size: 1.5rem;
    padding: 1rem;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
</style>