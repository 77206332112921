<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark class="rounded-0 red darken-2">
          <v-toolbar-title>Noodgeval</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="d-flex flex-column align-center pa-5" style="height: 70vh">
          <v-card-text class="text-center" style="width: fit-content">
            <v-row>
              <v-col cols="12">
                <span class="text-md-h1 text-h2 text-sm-h2" width="100%"
                  >Spoedkliniek</span
                >
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" class="text-justify">
                Indien uw patiënt spoedeisende, specialistische of uitgebreide
                zorg nodig heeft, staan wij 24/7 voor u en uw cliënten klaar. In
                onze dierenziekenhuis werken (Europees erkende) specialisten,
                gedifferentieerde dierenartsen en hoogopgeleide paraveterinairen
                op een specifieke discipline, waardoor wij uw doorgestuurde
                patiënt de beste behandeling kunnen bieden. Ons dierenziekenhuis
                staat in dienst van en als verlengstuk van uw dierenkliniek. Ons
                ervaren en uitgebreide team van spoeddierenartsen,
                (spoed)chirurgen, paraveterinairen en dierverzorgers van de
                Spoed en Opname afdeling staat voor alle spoedpatiënten 24/7
                klaar en heeft uitgebreide ervaring, kennis en kunde met de
                behandeling van ernstige multi-trauma patiënten. Ons
                dierenziekenhuis is uitgerust met een state-of-the-art opname,
                waarbij er 24/7 dierenartsen en paraveterinairen aanwezig zijn
                om voor uw patiënt te zorgen. U kunt uw telefoon na
                sluitingstijd doorschakelen naar ons spoednummer. Deze service
                is kosteloos voor praktijken die regelmatig naar ons
                doorverwijzen. Voordat u uw telefoon de eerste keer, of
                incidenteel naar ons gaat doorschakelen zouden we graag eerst
                even een keer contact met u hebben om uw wensen en onze
                mogelijkheden te bespreken. Dus schroom niet en laat het ons
                telefonisch 030 - 202 70 70 of per email
                (info@edz-nieuwegein.nl) weten als u met ons zou willen
                samenwerken.
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      dialog: this.dialogprop,
    };
  },
  props: {
    dialogprop: Boolean,
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.$emit("dialogClosed", true);
    },
  },
};
</script>

<style>
</style>