<template>
  <v-container>
    <v-row>
      <v-card width="100%"
        ><v-row class="ma-0">
          <v-col cols="12" xs="12" class="pa-0 ma-0">
            <v-img class="rounded" :src="location.img"></v-img>
          </v-col>
          <v-col
            cols="12"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              flex-grow: 1;
            "
          >
            <div
              class="hours"
              v-for="hour in location.openinghours"
              :key="hour.key"
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
                gap: 2rem;
                width: 100%;
              "
            >
              <div style="width: 100%; text-align: right; font-size: 0.7rem">
                VM: {{ hour.morning }}
              </div>
              <div style="width: 100%; text-align: left; font-size: 0.7rem">
                NM: {{ hour.afternoon }}
              </div>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-0">
          <v-col cols="12">
            <h3 style="text-align: center">{{ location.city }}</h3>
            <h6 style="text-align: center">{{ location.address }}</h6>
          </v-col>
        </v-row></v-card
      >
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    location: Object,
  },
};
</script>

<style>
</style>