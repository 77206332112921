<template>
  <v-container>
    <v-row class="gutter-lg">
      <v-col sm="6" cols="12" class="text-justify pr-sm-12">
        <h1 class="accent--text text-center mb-10">
          Sterkliniek Dierenartsen Heythuysen
        </h1>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non, expedita
        cum quas quibusdam, repudiandae eveniet pariatur porro incidunt
        voluptatem voluptatibus, amet rerum quod repellendus at natus possimus
        excepturi eaque repellat. A ratione deserunt beatae quae commodi harum
        facilis magnam molestias! Lorem ipsum dolor sit amet consectetur
        adipisicing elit. In labore voluptatum deserunt temporibus minus
        reiciendis nobis officia, sit dignissimos nemo similique obcaecati
        debitis, voluptatem sint commodi repudiandae quod veniam fugit
        consequuntur, a dicta enim eveniet voluptas cupiditate? Illo, voluptates
        ab optio, architecto nobis id nostrum minus corporis culpa, ducimus
        soluta! Facere deserunt tempora alias natus, culpa molestias? Quam
        necessitatibus minima, ratione autem doloremque nulla est illum harum
        qui exercitationem! Ut rem autem cupiditate hic magni reiciendis atque
        quaerat, optio quia!

        <h1 class="accent--text text-center mt-10">
          Ons beleid omtrent COVID-19
        </h1>
        <h3 class="accent--text mt-10">
          Beste collega dierenartsen en diereigenaren,
        </h3>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur
        laudantium, itaque eligendi consequatur fugiat vero modi voluptates.
        Obcaecati alias labore architecto assumenda possimus optio in
        repellendus sapiente excepturi, blanditiis consequuntur harum aliquid
        eos, veniam beatae doloribus deserunt vero distinctio esse deleniti
        repellat minus ut vitae? Nihil, praesentium ipsam, vitae itaque dolorum,
        cupiditate aperiam nobis eaque autem ut sed minus mollitia repellendus.
        Earum aliquid, nesciunt, temporibus libero consectetur consequatur
        praesentium dolores vitae, dolorum eos neque obcaecati sapiente nobis
        doloremque saepe commodi eligendi dolorem aliquam laborum numquam
        adipisci corporis minus voluptatum! Sed vero neque tenetur, nesciunt ea
        voluptates praesentium est molestiae non. Est, voluptate. Voluptatum
        atque quasi id incidunt nobis laborum, totam aspernatur facilis
        voluptates asperiores provident, autem vero optio rerum fugit. Lorem
        ipsum dolor sit amet consectetur, adipisicing elit. Culpa, ipsa
        explicabo. Dolores quaerat facere nobis asperiores aspernatur vel cum
        inventore sunt explicabo animi, distinctio aut voluptates sit
        cupiditate, omnis impedit.
      </v-col>
      <v-col sm="6" cols="12">
        <ArticleList />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ArticleList from "@/components/ArticleList";

export default {
  components: {
    ArticleList,
  },
};
</script>

<style>
</style>