<template>
  <div class="socmed_container accent">
    <v-list class="accent">
      <v-list-item class="px-1" href="#">
        <v-icon class="white--text soc_med_icon">mdi-facebook</v-icon>
      </v-list-item>
      <v-list-item class="px-1" href="#">
        <v-icon class="white--text soc_med_icon">mdi-instagram</v-icon>
      </v-list-item>
      <v-list-item class="px-1" href="#">
        <v-icon class="white--text soc_med_icon">mdi-linkedin</v-icon>
      </v-list-item>
      <v-list-item class="px-1" href="#">
        <v-icon class="white--text soc_med_icon">mdi-google</v-icon>
      </v-list-item>
      <v-list-item class="px-1" href="#">
        <v-img src="../assets/blank-img.png" width="1"></v-img>
      </v-list-item>
      <v-list-item class="px-1" href="#">
        <v-img src="../assets/MMC Logo.png" width="1"></v-img>
      </v-list-item>
      <v-list-item class="px-1" href="#">
        <v-img src="../assets/pawprint.png" width="1"></v-img>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.socmed_container {
  position: fixed;
  padding: 0.3rem;
  top: 40vh;
  z-index: 5;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  .soc_med_icon {
    font-size: 1.5rem;
    transition: transform 1s ease-out;
  }

  .soc_med_icon:hover {
    transform: scale(120%);
  }
}
</style>