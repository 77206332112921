<template>
  <div class="pl-md-12">
    <Article v-for="article in articles" :article="article" :key="article.id" />
  </div>
</template>

<script>
import Article from "@/components/Article";
export default {
  components: {
    Article,
  },
  data() {
    return {
      articles: [
        {
          id: 1,
          title: "Oproep aan dierenartsen!",
          author: "Veterinarian 1",
          description:
            "Onze KNO-arts en chirurg Annika Haagsman zoekt patiënten met een mogelijke Aspergillus gerelateerde rhinitis (Sinonasale Aspergillosis, SNA).",
          url: "https://www.edz-nieuwegein.nl/verwijskliniek/weke-delen-chirurgie/oproep",
          img: "https://WEU-AZ-WEB-NL-CDNEP.azureedge.net/mediacontainer/medialibraries/edznieuwegein/images/tekstfotos/tile_kno-onderzoek.jpg?ext=.jpg",
        },
        {
          id: 2,
          title: "Oproep hartonderzoek",
          author: "Veterinarian 2",
          description:
            "Marjolein den Toom en&nbsp;Frank van Steenbeek&nbsp;vragen uw hulp bij hun onderzoek naar de genetische achtergrond van aangeboren hartaandoeningen bij honden.",
          url: "https://www.edz-nieuwegein.nl/verwijskliniek/cardiologie-en-pulmonologie/onderzoek-hartaandoening",
          img: "https://WEU-AZ-WEB-NL-CDNEP.azureedge.net/mediacontainer/medialibraries/edznieuwegein/images/tekstfotos/tile_hartonderzoek.jpg?ext=.jpg",
        },
      ],
    };
  },
};
</script>

<style>
</style>