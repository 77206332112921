<template>
  <v-app>
    <SocMed />
    <Navbar />
    <Banner />
    <v-main class="pa-10">
      <router-view />
    </v-main>
    <Footer />
    <EmergencyDialog
      v-if="this.modalIsToggled"
      v-bind:dialogprop="this.modalIsToggled"
      @dialogClosed="modalIsToggled = !modalIsToggled"
    />
    <EmergencyButton @emergency_clicked="modalIsToggled = !modalIsToggled" />
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import SocMed from "@/components/SocMed";
import Banner from "@/components/Banner";
import EmergencyDialog from "@/components/EmergencyDialog";
import EmergencyButton from "@/components/EmergencyButton";
export default {
  name: "App",
  components: {
    Navbar,
    Footer,
    SocMed,
    Banner,
    EmergencyButton,
    EmergencyDialog,
  },
  data() {
    return {
      modalIsToggled: false,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
