<template>
  <v-container fluid class="accent elevated">
    <v-container>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="6"
          class="d-flex-column justify-center align-center"
        >
          <v-row class="align justify">
            <v-col class="py-0 white--text text-right">Email:</v-col>
            <v-col class="py-0 white--text">info@bestcaredierenartsen.nl</v-col>
          </v-row>
          <v-row>
            <v-col class="py-0 white--text text-right">Telefoon:</v-col>
            <v-col class="py-0 white--text">0777 77 77 77</v-col>
          </v-row>
          <v-row>
            <v-col class="py-0 white--text text-right">Fax:</v-col>
            <v-col class="py-0 white--text">0777 77 77 77</v-col>
          </v-row>
          <v-row>
            <v-col class="py-0 white--text text-right">Adres Hoofdzetel:</v-col>
            <v-col class="py-0 white--text"
              >Rue de X 59 <br />
              9000 Gent, België</v-col
            >
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="2"></v-col>
        <v-col cols="12" sm="12" md="4" class="justify-end align">
          <v-img
            src="../assets/BestCareDierenartsen.jpg"
            max-height="100"
            contain
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
v-row {
  padding: 0;

  v-col {
    padding: 0;
    text-align: center;
  }
}
</style>