<template>
  <div class="center-icon-bar">
    <v-container class="secondary my-15" fluid>
      <v-row
        class="icon-row"
        justify="center"
        style="max-width: 70%; margin: 0 auto"
      >
        <v-col
          cols="12"
          xs="12"
          sm="4"
          md="4"
          lg="3"
          xl="2"
          v-for="logo in logos"
          :key="logo.id"
          class="pa-5"
        >
          <v-img :src="logo.img" class="logo-img rounded"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logos: [
        {
          id: 1,
          img: require("../assets/BestCareDierenartsen.jpg"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.center-icon-bar {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>