<template>
  <v-btn
    @click="$emit('emergency_clicked', true)"
    bottom
    left
    fixed
    dark
    class="red darken-2 rounded-pill pa-6"
    ><v-icon left>mdi-hospital-box</v-icon>Noodgeval?</v-btn
  >
</template>

<script>
export default {};
</script>

<style>
</style>