<template>
  <div>
    <GeneralAndArticles />
    <LogoBar />
    <locationList />
  </div>
</template>

<script>
import Banner from "@/components/Banner";
import GeneralAndArticles from "@/components/GeneralAndArticles";
import LogoBar from "@/components/LogoBar";
import LocationList from "@/components/LocationList";
export default {
  components: {
    Banner,
    GeneralAndArticles,
    LogoBar,
    LocationList,
  },
};
</script>

<style lang="scss" scoped>
</style>
