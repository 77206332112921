<template>
  <v-card class="mt-10">
    <v-img height="250" :src="article.img"></v-img>
    <v-card-title class="accent--text">{{ article.title }}</v-card-title>
    <v-card-subtitle class="pl-5">door {{ article.author }}</v-card-subtitle>
    <v-divider></v-divider>
    <v-card-actions class="text-justify">
      <v-card-text>{{ article.description }}</v-card-text>
    </v-card-actions>
    <v-btn text block class="accent--text">Meer Lezen</v-btn>
  </v-card>
</template>

<script>
export default {
  props: {
    article: Object,
  },
};
</script>

<style>
</style>